<template>
  <div>
    <b-card-code>
      <validation-observer ref="GroupEditForm" #default="{ invalid }">
        <b-form class="auth-login-form mt-2" @submit.prevent="editGroup">
          <!-- Organization-->
          <b-form-group
            v-if="is_staff"
            label="Organization"
            label-for="Organization"
          >
            <validation-provider
              #default="{ errors }"
              name="organization"
              rules="required"
            >
              <b-form-select
                v-model="organization"
                :options="organizations"
                :state="errors.length > 0 ? false : null"
                @change="onOrgChange"
                disabled
              ></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- team Name -->
          <b-form-group label="Team Name" label-for="Name">
            <template #label>
              <span>Team Name</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="Team Name"
              :rules="{
                required: true,
                regex: /^[a-zA-Z0-9\s\(\)\ [\]\ {\} \|\/ ]+$/,
              }"
            >
              <b-form-input
                id="team_name"
                v-model="team_name"
                :state="errors.length > 0 ? false : null"
                name="team_name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- Description -->
          <b-form-group label="Group Description" label-for="Group Description">
            <b-form-textarea
              id="team_description"
              v-model="team_description"
              name="team_description"
            />
          </b-form-group>

          <b-button
            type="submit"
            variant="primary"
            :disabled="invalid"
            class="my-1"
            size="sm"
          >
            <span v-if="isloading"
              ><b-spinner type="border" small></b-spinner> Please wait</span
            >
            <span v-else>Update</span>
          </b-button>
        </b-form>
      </validation-observer>
    </b-card-code>
  </div>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    vSelect,
    BSpinner,
  },
  data() {
    return {
      is_staff: this.$store.state.app.user.is_staff,
      team_name: "",
      team_description: "",
      organization: "",
      organizations: [{ value: null, text: "--Select Organization--" }],
      isloading: false,
    };
  },
  created: function () {
    this.load();
  },
  methods: {
    load: function () {
      var self = this;
      var selfi = this;
      if (this.is_staff) {
        // Load oragnization drop down
        const o_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "organization/organization/get-all-org/",
        };
        // var self = this;
        this.$http(o_options).then((res) => {
          // console.log(res.data)
          res.data.map(function (value, key) {
            let org = {
              value: res.data[key].org_id,
              text: res.data[key].org_name,
            };
            // console.log(res.data[key].profile.organization)
            self.organizations.push(org);
          });
        });
      }

      var self = this;
      // Also load existing assets and details of the group
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL + "user/teams/" + this.$route.params.id,
      };
      this.$http(options).then((res) => {
        this.team_name = res.data.team_name;
        this.team_description = res.data.team_description;
        this.organization = res.data.organization;
      });
    },

    editGroup: function () {
      this.$refs.GroupEditForm.validate().then((success) => {
        this.isloading = true;
        if (success) {
          //call to axios
          console.log("success");
          var self = this;
          if (!this.is_staff)
            this.organization = this.$store.state.app.organizationId;
          let data = {
            team_name: this.team_name,
            team_description: this.team_description,
            organization: this.organization,
          };
          const options = {
            method: "PATCH",
            headers: { "content-type": "application/json" },
            data: data,
            url:
              process.env.VUE_APP_BASEURL +
              "user/teams/" +
              this.$route.params.id +
              "/",
          };
          this.$http(options).then((res) => {
            console.log(res);
            if (res.data.team_id) {
              this.$router.push({ name: "Teams" });
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: "Edited Successfully",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.isloading = true;
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
